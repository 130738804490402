<template>
  <div class="page-controller">
    <span
      class="btn"
      :class="{'disabled': current === 1}"
      @click="toPrevPage"
    >上一页</span>
    <span class="jumper">
      {{ current }}/{{ total }}
    </span>
    <span
      class="btn"
      :class="{'disabled': current === total}"
      @click="toNextPage"
    >下一页</span>
  </div>
</template>
<script>
export default {
  name: 'PageController',
  props: {
    current: {
      type: [Number, String],
      default: 0,
    },
    total: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // 上一页
    toPrevPage() {
      this.$emit('to-prev-page');
    },
    // 下一页
    toNextPage() {
      this.$emit('to-next-page');
    },
  },
};
</script>
<style lang="scss" scoped>
  .page-controller {
    float: left;
    display: flex;
    font-size: 12px;
    position: absolute;
    left: 50%;
    z-index: 99;
    margin-left: -102px;
    margin-top: 14px;
    color: #959aa2;

    .btn {
      width: 68px;
      height: 22px;
      text-align: center;
      line-height: 20px;
      border: 1px solid #9699ac;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background: #373946;
        color: #f9fafc;
      }

      &.disabled {
        background: #2c313d;
        color: #666b71;
        border-color: #151a28;
        cursor: not-allowed;
      }
    }

    .jumper {
      width: 68px;
      line-height: 22px;
      text-align: center;
    }
  }
</style>
